<nav class="navbar navbar-expand-lg ps-3" id="navigation">
	<div class="w-100 d-none d-lg-block">
		<div class="d-flex justify-content-between">
			<!-- Top Navigation Bar -->
			<div class="d-flex align-items-center">
				<a class="navbar-brand theme__images--productLogoNav" [routerLink]="['/']"> </a>

				<!-- Top Nav Icon Links -->
				<div class="nav mt-1 ms-4">
					<app-icon-text
						[routerLink]="[item.routeKey]"
						*ngFor="let item of topMenu"
						[iconName]="item.icon"
						[inputText]="item.text"
						class="spacer"
						[active]="activeLink === item.routeKey"
						(click)="activeLink = item.routeKey">
					</app-icon-text>
				</div>
			</div>

			<!-- User Menu -->
			<div class="ms-auto d-flex align-items-center" [matMenuTriggerFor]="menu">
				<app-icon-text [iconName]="'account_circle'" mat-icon-button [inputText]="fullName"></app-icon-text>
				<app-icon-text
					[iconName]="'arrow_drop_down'"
					class="ms-3 me-1"
					mat-icon-button
					[inputText]="' '"></app-icon-text>

				<mat-menu class="user-menu" #menu="matMenu">
					<div class="d-flex align-items-center cursor-pointer mt-2 p-2" [routerLink]="['/settings']">
						<mat-icon class="material-icons-outlined mat-nav-icon">settings</mat-icon>
						<span class="text-pos icon-text">User Settings</span>
					</div>

					<div
						*ngFor="let m of userMenu"
						class="d-flex align-items-center cursor-pointer mt-2 p-2"
						[routerLink]="[m.routeKey]">
						<mat-icon class="material-icons-outlined mat-nav-icon">{{ m.icon }}</mat-icon>
						<span class="text-pos icon-text">{{ m.text }}</span>
					</div>
					<mat-divider class="border-white mx-3 my-1"></mat-divider>
					<div class="d-flex align-items-center cursor-pointer p-2" (click)="logout()">
						<mat-icon class="material-icons-outlined mat-nav-icon">logout</mat-icon>
						<span class="text-pos icon-text">Logout</span>
					</div>
				</mat-menu>
			</div>
		</div>
	</div>

	<!-- Small Device -->
	<div class="d-block d-lg-none w-100">
		<div class="d-flex justify-content-between align-items-center">
			<mat-icon class="material-icons icon-color" aria-hidden="false" aria-label="icon" (click)="toggleSideMenu()"
				>menu</mat-icon
			>

			<a class="navbar-brand ms-2 theme__images--productLogoNav" [routerLink]="['/']">
				<!-- <img src="assets/images/logo.svg" height="40" alt="SportsEye"> -->
			</a>
			<mat-icon class="material-icons-outlined me-4 icon-color" (click)="closeSideMenu()" [matMenuTriggerFor]="menu2">
				account_circle
			</mat-icon>
		</div>

		<mat-menu #menu2="matMenu" class="user-menu">
			<div class="d-flex align-items-center cursor-pointer userMenu p-2" [routerLink]="['/settings']">
				<mat-icon class="material-icons-outlined mat-nav-icon">settings</mat-icon>
				<span class="text-pos icon-text">User Settings</span>
			</div>

			<!-- DYNAMIC MENU -->
			<div *ngFor="let m of userMenu" class="d-flex align-items-center cursor-pointer p-2" [routerLink]="[m.routeKey]">
				<mat-icon class="material-icons-outlined mat-nav-icon">{{ m.icon }}</mat-icon>
				<span class="text-pos icon-text">{{ m.text }}</span>
			</div>

			<mat-divider class="border-white mx-3 my-1"></mat-divider>

			<div class="d-flex align-items-center cursor-pointer p-2" (click)="logout()">
				<mat-icon class="material-icons-outlined mat-nav-icon">logout</mat-icon>
				<span class="text-pos icon-text">Logout</span>
			</div>
		</mat-menu>
	</div>
</nav>
