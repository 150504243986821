import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { EtlService } from 'src/app/core/services/etl.service';
import { UserService } from 'src/app/core/services/user.service';
import { DataIssueSummary } from 'src/app/shared/models/dataIssueSummary';
import { NavigationItem, } from 'src/app/shared/models/UserUI';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	greetingTitle: string = '';
	greetingSubTitle: string = '';
	userOrganisationId: number = 0;
	showUploadData: boolean = false;

	//-- Navigation Tiles
	navTiles: NavigationItem[] = [];

	//-- Data Insights
	dataIssues: DataIssueSummary;

	constructor(
		private authService: AuthService,
		private userService: UserService,
		private router: Router,
		private etlService: EtlService
	) { }



	ngOnInit(): void {
		this.navTiles = this.authService.getNavTiles();
		console.log(this.navTiles);
		this.userOrganisationId = this.authService.getUserOrganisationId();

		this.setGreeting();
		this.getDataIssueSummary();
	}


	setGreeting() {
		let userName = this.authService.getFirstName();

		let hrs = new Date().getHours();
		this.greetingTitle = 'Good Morning ' + userName;
		if (hrs >= 12 && hrs < 18)
			this.greetingTitle = 'Good Afternoon ' + userName;
		else if (hrs >= 18)
			this.greetingTitle = 'Good Evening ' + userName;

		let role: string = this.authService.getUserRole();

		if(role == 'Organisation Admin' || role == 'SportsEye Admin')
			this.showUploadData = true;

		let platform: string = this.authService.getPlatform();
		this.greetingSubTitle = `Welcome to ActiveXchange ${platform} | ${role}`;

	}

	goto(link: string) {
		this.router.navigate([link]);
	}

	uploadData() {
		this.router.navigate([`/admin/organisations/${this.userOrganisationId}/jobs`]);
	}

	/** Retrieve data issues summary */
	getDataIssueSummary() {
		//this.o(this.userOrganisationId.toString()).subscribe(
		//	data => {
		//		this.dataIssues = data;
		//	}
		//);
	}
}
