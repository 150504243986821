<div
	class="upload-area p-2 row"
	appDragAndDrop
	(fileDropped)="selectFiles($event)"
	[dragDropDisabled]="!multiSelect && selectedFiles.length > 0"
	[class.disabled]="!multiSelect && selectedFiles.length > 0">
	<div class="col d-flex flex-column align-items-center">
		<mat-icon class="row upload-icon mb-2">cloud_upload</mat-icon>
		<span class="row">{{ 'Drag and drop file' + (multiSelect ? 's' : '') + ' here' }}</span>
		<span class="row mt-2">or</span>
		<input
			#fileInput
			type="file"
			class="native-file-input"
			[accept]="acceptedTypes.join()"
			[multiple]="multiSelect"
			(change)="selectFiles(fileInput.files)" />
		<button
			mat-flat-button
			class="row browse-button mt-3"
			(click)="fileInput.click()"
			[disabled]="!multiSelect && selectedFiles.length > 0">
			BROWSE
		</button>
	</div>
</div>
<div class="row d-flex justify-content-center" *ngIf="selectedFiles.length === 0">
	<app-empty-state
		iconName="note_add"
		[notificationText]="'No file' + (multiSelect ? 's' : '') + ' selected'"
		[callToActionText]="'Add ' + (multiSelect ? 'files' : 'a file') + ' above'"
		[compact]="true">
	</app-empty-state>
</div>

<ng-container *ngIf="selectedFiles.length > 0">
	<span class="row mt-3">
		<h3>{{ 'Selected File' + (multiSelect ? 's' : '') }}</h3>
	</span>
	<mat-list class="row">
		<mat-list-item *ngFor="let selectedFile of selectedFiles; let index = index">
			<div class="d-flex align-items-center w-100 gap-1 item">
				<span class="flex-grow-1">{{ selectedFile.file.name }}</span>
				<span
					*ngIf="selectedFile.status === FileStatus.Pending || selectedFile.status === FileStatus.Next"
					class="status status__pending">
					Upload queued
				</span>
				<span *ngIf="selectedFile.status === FileStatus.Progress" class="status status__progress">
					{{ 'Uploading... ' + selectedFile.progress + '%' }}
				</span>
				<span *ngIf="selectedFile.status === FileStatus.Success" class="status status__success"> Upload complete </span>
				<span *ngIf="selectedFile.status === FileStatus.Error" class="status status__error"> Upload failed </span>
				<button
					mat-icon-button
					disableRipple
					matTooltip="Remove file"
					matTooltipShowDelay="500"
					matTooltipPosition="right"
					(click)="removeFile(selectedFile)"
					*ngIf="selectedFile.status === FileStatus.Added || selectedFile.status === FileStatus.Error"
					class="list-item-button">
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</mat-list-item>
	</mat-list>
	<div class="row mt-2 d-flex flex-row-reverse gap-2">
		<app-icon-button
			icon="upload"
			(buttonClicked)="upload()"
			color="green"
			size="small"
			text="UPLOAD"
			[disabled]="readyFiles.length === 0"
			*ngIf="uploadingFiles.length === 0">
		</app-icon-button>
		<app-icon-button
			icon="cancel"
			(buttonClicked)="cancel()"
			color="red"
			size="small"
			text="STOP"
			*ngIf="uploadingFiles.length > 0">
		</app-icon-button>
	</div>
</ng-container>
