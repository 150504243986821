import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from '../services/message.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(public authService: AuthService, public router: Router, public messageService: MessageService, private route: ActivatedRoute) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (request.url.startsWith(environment.academyApi)) {
			return next.handle(request);
		}

		if (request.url.indexOf('Upload') !== -1 || request.url.indexOf('Reports') !== -1) {
			console.log(request.url);
		} else {
			request = request.clone({
				headers: request.headers.set('Content-Type', 'application/json')
			});


		}


		if (request.url.indexOf('Auth') !== -1) {
			console.log('Calling ' + request.url);
			return next.handle(request);
		}
		///

		const tokenExpired = this.authService.tokenExpired();


		if (tokenExpired) {
			console.log('Token is Expired ');
			if (!this.refreshTokenInProgress) {
				console.log('Requesting New Token');
				this.refreshTokenInProgress = true;
				this.refreshTokenSubject.next(null);
				return this.authService.refreshToken().pipe(
					switchMap((authResponse) => {
						console.log('New Token Received');
						this.authService.setToken(authResponse.token);
						this.authService.setRefreshToken(authResponse.refreshToken);
						this.refreshTokenSubject.next(authResponse.refreshToken);
						this.refreshTokenInProgress = false;
						console.log('Now Calling ' + request.url);

						return next.handle(this.injectToken(request));

					}) //end of Switch map

					//Solution From https://github.com/angular/angular/issues/21149
					, catchError((error: HttpErrorResponse) => this.refreshErrorHandler(error))

				) as Observable<HttpEvent<any>>;
			} else {
				console.log('Refresh Token is in Progress');
				return this.refreshTokenSubject.pipe(
					filter(result => result !== null),
					take(1),
					switchMap((res) => {
						console.log('Calling Api ' + request.url);
						return next.handle(this.injectToken(request))
					})
				);
			}
		} else {

			//console.log('Token is Alive, Calling ' + request.url);
			//return next.handle(this.injectToken(request)).pipe(catchError((error: HttpErrorResponse)=> this.errorHandler(error)));
			var req = this.injectToken(request);
			//console.log(req);
			return next.handle(req);

			////
		}


	}




	injectToken(request: HttpRequest<any>) {
		const token = this.authService.getToken();
		//return request.headers.append('Authorization',`Bearer ${token}` );
		if (request.url.includes('Upload') || request.url.includes('Reports')) {
			console.log('request Type: multipart/form-data')
			return request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		}
		else {
			return request.clone({
				setHeaders: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			});
		}
	}


	refreshErrorHandler(error: any): Observable<any> {

		if (error && error.status && error.status == 401) {
			this.messageService.isLoggedIn(false);
			this.authService.clearSessionStorage();

			this.router.navigate(['login'], { queryParams: { 'redirectURL': this.router.routerState.snapshot.url } });

		}
		else {
			console.log(error);
			let errorMsg = (error.message.length < 200) ? error.message : 'Something went wrong.';
			this.messageService.showError(errorMsg);
		}
		return throwError(error);
		//return of({});
	}

	errorHandler(err: any): Observable<any> {
		if (err.error instanceof ErrorEvent)
			console.log('Client side error' + err.error);
		else
			console.log('Server side error');

		let errorMsg = '';
		if (err.error) {
			if (err.error.status == 400)
				errorMsg = err.error.title;
			else
				errorMsg = err.error.message;
		}

		errorMsg = (errorMsg && errorMsg.length > 0 && errorMsg.length < 200) ? errorMsg : 'Something went wrong.';

		this.messageService.showError(errorMsg);

		return throwError(err);

	}



}
