export class CacheKeys{
    public static readonly UserName: string = 'userName';
    public static readonly UserFullName: string = 'fullName';
    public static readonly UserFirstName = 'firstName';
    public static readonly Token: string = 'token';
    public static readonly RefreshToken: string = 'refreshToken';
    public static readonly Enums: string = 'enums';
    public static readonly IsUserLoggedIn = 'isLoggedIn';
    public static readonly RememberMe = 'true';
    public static readonly UserRole = 'userRole';
    public static readonly OrganisationId = 'organisationId';
    public static readonly UserInfo: string = 'userInfo';
    public static readonly Platform = 'platform';

    public static readonly NavTiles = 'tile';
    public static readonly TopMenu = 'topMenu';
    public static readonly UserMenu = 'userMenu';
    public static readonly Country = 'country';
	public static readonly FeaturesFlag = 'featuresFlag';

}
