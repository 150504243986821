import { environment } from '../../environments/environment';

export class Api {
	public static baseApiUrl = environment.baseSEApiUrl;

	public static authApiUrl = Api.baseApiUrl + '/api/v1/Auth';
	public static refreshTokenApiUrl = Api.baseApiUrl + '/api/v1/Auth/RefreshToken';
	public static passwordRequestResetUrl: string = Api.baseApiUrl + '/api/v1/Auth/password/request';
	public static passwordResetUrl: string = Api.baseApiUrl + '/api/v1/Auth/password/reset';

	public static getEnumsUrl = Api.baseApiUrl + '/api/v1/Enums';
	public static getEnumUrl = Api.baseApiUrl + '/api/v1/Enums/{id}';

	public static getIdentityRoles = Api.baseApiUrl + '/api/v1/Identity/Roles';

	public static getOrganisationsUrl = Api.baseApiUrl + '/api/v1/Organisations';
	public static getOrganisationUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}';
	public static postOrganisationUrl = Api.baseApiUrl + '/api/v1/Organisations';
	public static putOrganisationUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}';
	public static getOrganisationUsersUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}/users';
	public static getOrganisationSitesUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}/sites';
	public static getOrganisationSiteGroupsUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}/sitegroups';
	public static getOrganisationMembershipTypesUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}/membershiptypes';
	public static getOrganisationActivitiesUrl = Api.baseApiUrl + '/api/v1/Organisations/{id}/activities';
	public static putOrganisationSaveDatayear = Api.baseApiUrl + '/api/v1/Organisations/UpdateSportsProDataset';
	public static getOrganisationSiteGroupSitesUrl = Api.baseApiUrl + '/api/v1/Organisations/{orgId}/sitegroup/{id}';
	public static getSportsProDatsetYearsUrl: string = Api.baseApiUrl + '/api/v1/Organisations/{id}/datasets';
	public static getOrganisationNidConflicts: string = Api.baseApiUrl + '/api/v1/OrganisationSites/{id}/conflict/{jobId}';

	public static getOrganisationTeamsUrl = Api.baseApiUrl + '/api/v1/teams/organisationTeams/{id}';
	public static getOrganisationTeamUrl = Api.baseApiUrl + '/api/v1/teams/{teamId}';
	public static postOrganisationTeamUrl = Api.baseApiUrl + '/api/v1/teams';
	public static deleteOrganisationTeamUrl = Api.baseApiUrl + '/api/v1/teams/{teamId}';

	public static getAxActivitiesUrl = Api.baseApiUrl + '/api/v1/AxActivities';
	public static getAxActivityProgrammesUrl = Api.baseApiUrl + '/api/v1/AxActivityProgrammes';
	public static getAxMembershipTypesUrl = Api.baseApiUrl + '/api/v1/AxMembershipTypes';

	public static postUserUrl: string = Api.baseApiUrl + '/api/v1/Users';
	public static putUserUrl: string = Api.baseApiUrl + '/api/v1/Users';
	public static passwordChangeUrl: string = Api.baseApiUrl + '/api/v1/Users/password/update';

	public static postReportUrl: string = Api.baseApiUrl + '/api/v1/Reports';
	public static fetchOrganisationReportUrl = Api.baseApiUrl + '/api/v1/Reports/{id}/reportfetch';
	public static getOrganisationReportsUrl = Api.baseApiUrl + '/api/v1/Reports/{id}/reports';
	public static getOrganisationReportsByIdUrl = Api.baseApiUrl + '/api/v1/Reports/{id}';
	public static deleteOrganisationReportsUrl = Api.baseApiUrl + '/api/v1/Reports/{id}';

	public static logoutUrl: string = Api.baseApiUrl + '/api/v1/Users/Logout';

	public static getUserUrl: string = Api.baseApiUrl + '/api/v1/Users/{id}';
	public static postUserOrganisationUrl: string = Api.baseApiUrl + '/api/v1/Users/{id}/organisation';

	public static putEnableUserUrl: string = Api.baseApiUrl + '/api/v1/Users/enable';
	public static putDisUserUrl: string = Api.baseApiUrl + '/api/v1/Users/disable';
	public static userMovementEmailSubscription: string = Api.baseApiUrl + '/api/v1/users/movementemail';

	//NID
	public static getSitePoints: string = Api.baseApiUrl + '/api/v1/nidSites';
	public static getSitePointsWithFilters: string = Api.baseApiUrl + '/api/v1/NidSites/Filter';
	public static getFilteredNidSiteListUrl: string = Api.baseApiUrl + '/api/v1/nidSites/filter/{searchKey}';
	public static getOrganisationSitePoints: string = Api.baseApiUrl + '/api/v1/OrganisationSites/{organisationId}';
	public static getOrganisationSitePointsWithActionPlans: string =
		Api.baseApiUrl + '/api/v1/OrganisationSites/WithActionPlans/{organisationId}';

	//Map Startup
	public static getMapStartupParams: string = Api.baseApiUrl + '/api/v1/maps/mapStartup';
	public static getMapPanelUI_Url: string = Api.baseApiUrl + '/api/v1/maps/mapPanel';

	public static getSiteUrl: string = Api.baseApiUrl + '/api/v1/nidSites/{id}';
	public static updateSiteUrl: string = Api.baseApiUrl + '/api/v1/NidSites/SaveNIDLocation';

	public static postSiteUrl: string = Api.baseApiUrl + '/api/v1/nidSites';
	public static putSiteUrl: string = Api.baseApiUrl + '/api/v1/nidSites/{id}';

	public static getFacilityTypesUrl: string = Api.baseApiUrl + '/api/v1/NidFacilityTypes';
	public static getFacilitySpaceTypeMetadataUrl: string = Api.baseApiUrl + '/api/v1/NidFacilities/SpaceTypeMetadata';

	// These can be depricated
	public static getFacilitySubTypesUrl: string = Api.baseApiUrl + '/api/v1/NidFacilitySubTypes';
	public static getFacilitySubTypeMetaDataUrl: string = Api.baseApiUrl + '/api/v1/NidFacilitySubTypeMetadata';

	public static getFacilitiesUrl: string = Api.baseApiUrl + '/api/v1/nidFacilities';
	public static getFacilitiesForSiteUrl: string = Api.baseApiUrl + '/api/v1/nidFacilities/Site/{siteId}';
	public static getFacilityUrl: string = Api.baseApiUrl + '/api/v1/nidFacilities/{id}';
	public static postFacilityUrl: string = Api.baseApiUrl + '/api/v1/nidFacilities';
	public static putFacilityUrl: string = Api.baseApiUrl + '/api/v1/nidFacilities/{id}';
	public static deleteFacilityUrl: string = Api.baseApiUrl + '/api/v1/nidFacilities/{id}';

	public static getFacilityUsagesUrl: string = Api.baseApiUrl + '/api/v1/NidFacilityUsages/Facility/{id}';
	public static getFacilityUsageUrl: string = Api.baseApiUrl + '/api/v1/NidFacilityUsages/{id}';
	public static postFacilityUsageUrl: string = Api.baseApiUrl + '/api/v1/NidFacilityUsages';
	public static putFacilityUsageUrl: string = Api.baseApiUrl + '/api/v1/NidFacilityUsages/{id}';
	public static deleteFacilityUsageUrl: string = Api.baseApiUrl + '/api/v1/NidFacilityUsages/{id}';

	public static getSiteActionPlansUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlans/Site/{id}';
	public static getActionPlanUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlans/{id}';
	public static postActionPlanUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlans';
	public static putActionPlanUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlans/{id}';
	public static deleteActionPlanUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlans/{id}';

	public static getActionPlanCommentUrl: string =
		Api.baseApiUrl + '/api/v1/NidActionPlanComments/ActionPlan/{actionPlanId}';
	public static postActionPlanCommentUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlanComments';
	public static deleteActionPlanCommentUrl: string = Api.baseApiUrl + '/api/v1/NidActionPlanComments/{id}';

	public static getActivityProgrammesUrl: string = Api.baseApiUrl + '/api/v1/AxActivityProgrammes';
	public static getActivitiesUrl: string = Api.baseApiUrl + '/api/v1/AxActivities';

	//-- ETL Jobs Module
	public static etlFileUploadUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/data';
	public static etlFileUploadHistoryUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/history/{id}';
	public static etlJobErrorsUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/errors/{id}';
	public static etlJobProcessUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/process/{id}';
	public static etlNIDFileUploadUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/niddata';
	public static etlJobHistory: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/job/{id}/history';

	////Expired/
	////public static etlJobUnMappedSitesUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/unmapped/sites/{id}'
	////public static etlJobUnMappedMembershipsSportsUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/sports/unMapped/MembershipTypes/{id}'
	////public static etlJobUnMappedMembershipsOperatorUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/operator/unMapped/MembershipTypes/{id}'
	////public static etlJobUnMappedActivitiesOperatorUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/operator/unMapped/activities/{id}'

	//public static etlMapSiteUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/map/OrgSites'
	//public static etlDataIssueSummaryUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/lastjob/issues/{id}'

	public static getEtlJobUrl: string = Api.baseApiUrl + '/api/v1/EtlFileUpload/job/{id}';

	public static postRangeOrgMembershipTypesUrl: string =
		Api.baseApiUrl + '/api/v1/OrganisationMembershipTypes/Range/{organisationId}';
	public static postRangeOrgActivitiesUrl: string =
		Api.baseApiUrl + '/api/v1/OrganisationActivities/Range/{organisationId}';
	public static postRangeOrgSitesUrl: string = Api.baseApiUrl + '/api/v1/OrganisationSites/Range';

	// Unmapped
	public static getUnmappedEntitySummaryUrl: string =
		Api.baseApiUrl + '/api/v1/OrganisationMapping/{organisationId}/unMappedEntitySummary';
	public static getUnmappedOrganisationSitesUrl: string = Api.baseApiUrl + '/api/v1/OrganisationSites/{id}/unMapped';
	public static getUnmappedMembershipTypesUrl: string =
		Api.baseApiUrl + '/api/v1/OrganisationMembershipTypes/{id}/unMapped';
	public static getUnmappedActivitiesUrl: string = Api.baseApiUrl + '/api/v1/OrganisationActivities/{id}/unMapped';

	// Mapping Data
	public static getSportsMemberDemandUrl: string = Api.baseApiUrl + '/api/v1/SportsDemand/maps';
	public static getMemberDemandDataUrl: string =
		Api.baseApiUrl + '/api/v1/maps/memberDemandData/{organisationId}/{geographicLevel}';

	// Jobs Management
	public static restartJobUrl: string = Api.baseApiUrl + '/api/v1/Jobs/restart/{orgId}';
	public static deleteAllJobDataUrl: string = Api.baseApiUrl + '/api/v1/Jobs/deleteall/{orgId}';
}
