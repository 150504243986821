import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Observable } from 'rxjs';
import { Api } from '../../configs/config';
import { OrganisationDto, UnmappedEntitySummaryDto } from 'src/app/shared/models/organisation';
import { UnmappedActivityDto } from 'src/app/shared/models/organisation/unmappedActivityDto';
import { environment } from '../../../environments/environment';
import { OrganisationSiteGroups, TeamDto, UnmappedMembershipTypeDto } from 'src/app/shared/models/organisation';
import { UserDto } from 'src/app/shared/models/userDto';
import { OrganisationSiteDto } from 'src/app/shared/models/organisation/organisation-site-dto.model';

@Injectable({
	providedIn: 'root',
})
export class OrganisationService {
	constructor(private http: HttpClient, private messageService: MessageService) {}

	createOrganisation(org): Observable<any> {
		return this.http.post<OrganisationDto>(Api.postOrganisationUrl, org);
	}

	updateOrganisation(id: number, org): Observable<any> {
		return this.http.put<OrganisationDto>(Api.putOrganisationUrl.replace('{id}', id.toString()), org);
	}

	getOrganisation(id: string): Observable<OrganisationDto> {
		return this.http.get<OrganisationDto>(Api.getOrganisationUrl.replace('{id}', id));
	}

	getOrganisations(): Observable<OrganisationDto[]> {
		return this.http.get<OrganisationDto[]>(Api.getOrganisationsUrl);
	}

	getOrganisationUsers(organisationId: number): Observable<UserDto[]> {
		return this.http.get<any>(Api.getOrganisationUsersUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationSites(organisationId: number): Observable<OrganisationSiteDto[]> {
		return this.http.get<OrganisationSiteDto[]>(Api.getOrganisationSitesUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationSiteGroups(organisationId: number): Observable<any> {
		return this.http.get<any>(Api.getOrganisationSiteGroupsUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationSiteGroupSites(organisationId: number, siteGroupId: number): Observable<any> {
		return this.http.get<any>(
			Api.getOrganisationSiteGroupSitesUrl
				.replace('{orgId}', organisationId.toString())
				.replace('{id}', siteGroupId.toString())
		);
	}

	getOrganisationMembershipTypes(organisationId: number): Observable<any> {
		return this.http.get<any>(Api.getOrganisationMembershipTypesUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationActivities(organisationId: number): Observable<any> {
		return this.http.get<any>(Api.getOrganisationActivitiesUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationTeams(organisationId: number): Observable<TeamDto[]> {
		return this.http.get<TeamDto[]>(Api.getOrganisationTeamsUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationTeam(teamId: number): Observable<TeamDto> {
		return this.http.get<TeamDto>(Api.getOrganisationTeamUrl.replace('{teamId}', teamId.toString()));
	}

	getAxActivities(): Observable<any> {
		return this.http.get<any>(Api.getAxActivitiesUrl);
	}

	getAxActivityProgrammes(): Observable<any> {
		return this.http.get<any>(Api.getAxActivityProgrammesUrl);
	}
	getAxMembershipTypes(): Observable<any> {
		return this.http.get<any>(Api.getAxMembershipTypesUrl);
	}

	postOrgMembershipTypes(organisationId: number, lst: UnmappedMembershipTypeDto[]): Observable<any> {
		return this.http.post<UnmappedMembershipTypeDto[]>(
			Api.postRangeOrgMembershipTypesUrl.replace('{organisationId}', organisationId.toString()),
			lst
		);
	}

	postOrgActivities(organisationId: number, lst: UnmappedActivityDto[]): Observable<any> {
		return this.http.post<UnmappedActivityDto[]>(
			Api.postRangeOrgActivitiesUrl.replace('{organisationId}', organisationId.toString()),
			lst
		);
	}

	updateActivity(activity: { id: number; organisationId: number; axActivityProgrammeId: number }): Observable<any> {
		const url = `${environment.baseSEApiUrl}/api/v1/OrganisationActivities/${activity.id}`;
		console.log('Running in service', activity);
		return this.http.put(url, activity);
	}

	updateMembership(membership: { id: number; organisationId: number; axMembershipTypeId: number }): Observable<any> {
		const url = `${environment.baseSEApiUrl}/api/v1/OrganisationMembershipTypes/${membership.id}`;
		console.log('Running in service', membership);
		return this.http.put(url, membership);
	}

	postSiteGroupSites(orgSiteGroupSite: OrganisationSiteGroups): Observable<any> {
		const url = `${environment.baseSEApiUrl}/api/v1/OrganisationSiteGroup/siteandsitegroup`;
		console.log('Running in service', orgSiteGroupSite);
		return this.http.post<OrganisationSiteGroups>(url, orgSiteGroupSite);
	}

	putOrganisationDatayear(formData): Observable<any> {
		return this.http.put(Api.putOrganisationSaveDatayear, formData);
	}

	updateSite(site: OrganisationSiteDto): Observable<any> {
		const url = `${environment.baseSEApiUrl}/api/v1/OrganisationSites/${site.id}`;
		return this.http.put(url, site);
	}

	getGeographicLevelIds(country, level): Observable<any> {
		const url = `${environment.baseSEApiUrl}/api/v1/geographiclevels/${country}/${level}`;
		return this.http.get<any>(url);
	}

	getSportsProDatsetYear(organisationId: number): Observable<any> {
		return this.http.get<any>(Api.getSportsProDatsetYearsUrl.replace('{id}', organisationId.toString()));
	}

	getGeographicScopeTypes(country) {
		const url = `${environment.baseSEApiUrl}/api/v1/geographiclevels/${country}?scopeOnly=true`;
		return this.http.get<any>(url);
	}

	/** Given a Platform Identifier - determine if this is a Pro Account (platformInd ends with a 1) */
	isPlatformProAccount(platformInd: number): boolean {
		return platformInd % 10 == 1;
	}

	/** Return a summary of the total number of unmapped entities for a given organisation */
	getUnmappedEntitySummary(organisationId: number): Observable<UnmappedEntitySummaryDto> {
		return this.http.get<any>(Api.getUnmappedEntitySummaryUrl.replace('{organisationId}', organisationId.toString()));
	}

	postTeam(team: TeamDto): Observable<any> {
		return this.http.post(Api.postOrganisationTeamUrl, team);
	}

	deleteTeam(teamId: number): Observable<any> {
		return this.http.delete(Api.deleteOrganisationTeamUrl.replace('{teamId}', teamId.toString()));
	}

	getNIDConflicts(organisationId: number, jobId: number): Observable<any> {
		return this.http.get(
			Api.getOrganisationNidConflicts.replace('{id}', organisationId.toString()).replace('{jobId}', jobId.toString())
		);
	}

	postNIDConflictResolution(organisationId: number, jobId: number, data: number[]): Observable<any> {
		return this.http.post(
			Api.getOrganisationNidConflicts.replace('{id}', organisationId.toString()).replace('{jobId}', jobId.toString()),
			data
		);
	}
}
