import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { AxActivityProgramme } from 'src/app/shared/models/axActivityProgramme';
import { Facility } from 'src/app/shared/models/facility';
import { FacilityUsage } from 'src/app/shared/models/facilityUsage';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(private http: HttpClient) { }
  getFacilityUsages(id: number): Observable<FacilityUsage[]>{
    return this.http.get<FacilityUsage[]>(Api.getFacilityUsagesUrl.replace('{id}',id.toString()));
  }
  getUsage(id: number): Observable<FacilityUsage>{
    return this.http.get<FacilityUsage>(Api.getFacilityUsageUrl.replace('{id}',id.toString()));
  }
  getFacilities(): Observable<Facility[]> {
    return this.http.get<Facility[]>(Api.getFacilitiesUrl);
  }
  getFacilitiesForSite(siteId: number): Observable<Facility[]> {
    return this.http.get<Facility[]>(Api.getFacilitiesForSiteUrl.replace('{siteId}',siteId.toString()));
  }
  getFacility(id: number): Observable<Facility> {
      return this.http.get<Facility>(Api.getFacilityUrl.replace('{id}',id.toString()));
  }

  createFacility(f: Facility): Observable<any>{
    return this.http.post<Facility>(Api.postFacilityUrl,f);
  }

  updateFacility(id: number, f: Facility): Observable<any>{
    return this.http.put<Facility>(Api.putFacilityUrl.replace('{id}',id.toString()),f);
  }

  getFacilityTypes(): Observable<any>{
    return this.http.get(Api.getFacilityTypesUrl);
  }

  getAllFacilitySubTypes(): Observable<any>{
    return this.http.get(Api.getFacilitySubTypesUrl);
  }

  getFacilitySubTypeMetaData(): Observable<any>{
    return this.http.get(Api.getFacilitySubTypeMetaDataUrl);
  }

  createUsage(u: FacilityUsage): Observable<any>{
    return this.http.post(Api.postFacilityUsageUrl,u);
  }

  updateUsage(id: number, u: FacilityUsage): Observable<any>{
    return this.http.put(Api.putFacilityUsageUrl.replace('{id}',id.toString()),u);
  }

  deleteUsage(id: number): Observable<any>{
    return this.http.delete(Api.deleteFacilityUsageUrl.replace('{id}',id.toString()))
  }

  deleteFacility(id: number): Observable<any>{
    return this.http.delete(Api.deleteFacilityUrl.replace('{id}', id.toString()));
  }
  //TODO add facility siteid filter
  // getFacilitiesBySiteId(siteId: number): Observable<Facility[]>{

  // }

	/** Get the list of Facility Space Type Metadata */
	getFacilitySpaceTypeMetadata(): Observable<NidFacilitySpaceTypeMetadataDto[]>{
		return this.http.get<NidFacilitySpaceTypeMetadataDto[]>(Api.getFacilitySpaceTypeMetadataUrl);
	}
}

// DTO TYPES USED BY THIS SERVICE

export interface NidFacilitySpaceTypeMetadataDto{
	id: number;
	spaceTypeInd: number;
	question: string;
	suffix: string;
	helpText: string;
	typeInd: number;
	dropDownOptions: NidFacilitySpaceTypeMetadataDropDownOptionsDto[];

	decimalAnswer?: number;
	textAnswer?: string;
	dropDownAnswer?: number;
	operator?: number;
}

export interface NidFacilitySpaceTypeMetadataDropDownOptionsDto{
	id: number;
	optionText: string;
	inlineDescription: string;
	longDescription: string;
}
