<form [formGroup]="form" (ngSubmit)="submitForm()">
	<div class="button-bar">
		<app-icon-button
			icon="cancel"
			text="CANCEL"
			size="small"
			(buttonClicked)="cancel($event)"
			color="grey"></app-icon-button>
		<app-icon-button icon="check_circle" text="SAVE" size="small" type="submit"></app-icon-button>
	</div>

	<div style="padding: 15px">
		<div class="container-fluid">
			<section *ngIf="serverErrors.length > 0" class="error-panel-margin">
				<app-error-panel class="w-100" iconName="error" [inputText]="serverErrors[0]" padding="high"></app-error-panel>
			</section>

			<!-- Site Information -->
			<div class="row">
				<div class="col-12 col-lg-6">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Site Name</mat-label>
						<input matInput formControlName="name" required />
						<mat-error> Please enter site name </mat-error>
					</mat-form-field>
				</div>

				<div class="col-12 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Status</mat-label>
						<mat-select name="statusInd" formControlName="statusInd">
							<mat-option *ngFor="let s of status" [value]="s.id">{{ s.name }}</mat-option>
						</mat-select>
						<mat-error>Please select site status</mat-error>
					</mat-form-field>
				</div>

				<div class="col-12 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Condition</mat-label>
						<mat-select name="conditionInd" formControlName="conditionInd">
							<mat-option *ngFor="let v of conditions" [value]="v.id">{{ v.name }}</mat-option>
						</mat-select>
						<mat-error>Please select site condition</mat-error>
					</mat-form-field>
				</div>
			</div>

			<!-- Email / Web -->
			<div class="row">
				<div class="col-12 col-lg-6">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Contact Email</mat-label>
						<input matInput type="email" formControlName="contactEmail" />
						<mat-error> Please enter contact email </mat-error>
					</mat-form-field>
				</div>
				<div class="col-12 col-lg-6">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Website Address</mat-label>
						<input matInput type="url" formControlName="websiteURL" />
						<mat-error> Please enter contact email </mat-error>
					</mat-form-field>
				</div>
			</div>

			<b>Site Location</b>
			<mat-divider></mat-divider>

			<div class="row">
				<div class="col-12 col-lg-6">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Address</mat-label>
						<input matInput formControlName="address" required />
						<mat-error> Please enter site address </mat-error>
					</mat-form-field>
				</div>
				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Latitude</mat-label>
						<input matInput type="number" formControlName="latitude" required />
						<mat-error> Please enter latitude </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Longitude</mat-label>
						<input matInput type="number" formControlName="longitude" required />
						<mat-error> Please enter longitude </mat-error>
					</mat-form-field>
				</div>
			</div>

			<b>Operating Hours</b>
			<mat-divider></mat-divider>

			<!-- OPENING CLOSING HOURS -->
			<div class="row">
				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Weekday Opening Time</mat-label>
						<input matInput type="time" formControlName="openingHoursWeekdayOpenStr" />
						<mat-error> Please enter Weekday Opening Time </mat-error>
					</mat-form-field>
				</div>
				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Weekday Closing Time</mat-label>
						<input matInput type="time" formControlName="openingHoursWeekdayCloseStr" />
						<mat-error> Please enter Weekday Closing Time </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Weekend Opening Time</mat-label>
						<input matInput type="time" formControlName="openingHoursWeekendOpenStr" />
						<mat-error> Please enter Weekend Opening Time </mat-error>
					</mat-form-field>
				</div>
				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Weekend Closing Time</mat-label>
						<input matInput type="time" formControlName="openingHoursWeekendCloseStr" />
						<mat-error> Please enter Weekend Closing Time </mat-error>
					</mat-form-field>
				</div>
			</div>

			<b>Site Owner / Operator Details</b>
			<mat-divider></mat-divider>

			<!-- Owner -->
			<div class="row">
				<div class="col-12 col-lg-6">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Owner Name</mat-label>
						<input matInput formControlName="ownerName" />
						<mat-error> Please enter owner name </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Owner Type</mat-label>
						<mat-select name="ownerTypeInd" formControlName="ownerTypeInd">
							<mat-option *ngFor="let v of orgTypes" [value]="v.id">{{ v.name }}</mat-option>
						</mat-select>
						<mat-error>Please select owner type</mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field *ngIf="f.ownerTypeInd.value === 100" class="w-100" appearance="fill">
						<mat-label *ngIf="f.ownerTypeInd.value === 100">Type Other (Please specify)</mat-label>
						<input matInput formControlName="ownerTypeOther" />
						<mat-error>Please specify owner type</mat-error>
					</mat-form-field>
				</div>
			</div>

			<!-- Operator Name -->
			<div class="row">
				<div class="col-12 col-lg-6">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Operator Name</mat-label>
						<input matInput formControlName="operatorName" />
						<mat-error> Please enter operator name </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Operator Type</mat-label>
						<mat-select name="operatorTypeInd" formControlName="operatorTypeInd">
							<mat-option *ngFor="let v of orgTypes" [value]="v.id">{{ v.name }}</mat-option>
						</mat-select>
						<mat-error>Please select operator type</mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field *ngIf="f.operatorTypeInd.value === 100" class="w-100" appearance="fill">
						<mat-label *ngIf="f.operatorTypeInd.value === 100">Type Other (Please specify)</mat-label>
						<input matInput formControlName="operatorTypeOther" />
						<mat-error>Please specify operator type</mat-error>
					</mat-form-field>
				</div>
			</div>

			<b>Other Details</b>
			<mat-divider></mat-divider>

			<!-- Construction -->
			<div class="row">
				<div class="col-6 col-lg-2">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Built In (Approx)</mat-label>
						<input matInput type="number" formControlName="approxBuildYear" />
						<mat-error> Please enter valid build year </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-2">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Last Refurbished (Approx)</mat-label>
						<input matInput type="number" formControlName="approxLastRefurbYear" />
						<mat-error> Please enter valid last refurbish year </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-2">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Overall Site Size (m2)</mat-label>
						<input matInput type="number" formControlName="overallSiteSizeM2" />
						<mat-error> Please enter the overall site size in m2 </mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Accessibility</mat-label>
						<mat-select name="accessibilityInd" formControlName="accessibilityInd">
							<mat-option *ngFor="let v of accessibility" [value]="v.id">{{ v.name }}</mat-option>
						</mat-select>
						<mat-error>Please select accessibility</mat-error>
						<mat-hint>(able to access without barriers)</mat-hint>
					</mat-form-field>
				</div>
				<div class="col-6 col-lg-3">
					<mat-checkbox formControlName="accessibilityCertified"> Accessibility Certified? </mat-checkbox>
				</div>
			</div>

			<!-- Other Details -->
			<div class="row">
				<div class="col-4 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Food / Beverage Services</mat-label>
						<mat-select name="foodAndBeverageServiceInd" formControlName="foodAndBeverageServiceInd">
							<mat-option *ngFor="let v of foodAndBeverageServices" [value]="v.id">{{ v.name }}</mat-option>
						</mat-select>
						<mat-error>Please select food / beverage service</mat-error>
					</mat-form-field>
				</div>

				<div class="col-4 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Total Changing Rooms</mat-label>
						<input matInput formControlName="totalChangingRoooms" />
					</mat-form-field>
				</div>
				<div class="col-4 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Total Parking Spaces</mat-label>
						<input matInput formControlName="totalParkingSpaces" />
					</mat-form-field>
				</div>
				<div class="col-4 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Total Meeting Rooms</mat-label>
						<input matInput formControlName="totalMeetingFunctionRooms" />
					</mat-form-field>
				</div>
			</div>

			<b>Fees, Charges and Subsidies</b>
			<mat-divider></mat-divider>

			<div class="row mb-2">
				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Fees and Service Charges</mat-label>
						<mat-select name="chargingPolicyInd" formControlName="chargingPolicyInd">
							<mat-option *ngFor="let v of charginPolicies" [value]="v.id">{{ v.name }}</mat-option>
						</mat-select>
						<mat-error>Please select charging policy</mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3" *ngIf="f.chargingPolicyInd.value === 999">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Other Charges</mat-label>
						<input matInput formControlName="chargingPolicyOther" />
						<mat-error>Please specify charging policy</mat-error>
					</mat-form-field>
				</div>

				<div class="col-6 col-lg-3">
					<mat-form-field class="w-100" appearance="fill" floatLabel="always">
						<mat-label>Charging Policy Price</mat-label>
						<input matInput type="number" formControlName="chargingPolicyPrice" />
						<span matTextPrefix>$&nbsp;</span>
						<mat-error> Please enter average charging policy price </mat-error>
						<mat-hint>e.g. Av. monthly membership / hourly rental cost etc.</mat-hint>
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="d-flex flex-wrap column-gap-3 mb-2">
						<mat-checkbox
							*ngFor="let tpt of feeSubsidyFlags"
							[value]="tpt.id.toString()"
							[checked]="checkFlag(f.feeSubsidyFlag.value, tpt.id)"
							(change)="onFlagChange($event, 'feeSubsidyFlag', tpt.id)"
							>{{ tpt.name }}</mat-checkbox
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<b>Site Access</b> (Less than 100m away)
					<mat-divider></mat-divider>
					<div class="d-flex flex-wrap column-gap-3 mb-2">
						<mat-checkbox
							*ngFor="let tpt of transportFlags"
							[value]="tpt.id.toString()"
							[checked]="checkFlag(f.transportFlag.value, tpt.id)"
							(change)="onFlagChange($event, 'transportFlag', tpt.id)"
							>{{ tpt.name }}</mat-checkbox
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<b>Sports Services Available</b>
					<mat-divider></mat-divider>
					<div class="d-flex flex-wrap column-gap-3 mb-2">
						<mat-checkbox
							*ngFor="let tpt of sportServicesFlags"
							[value]="tpt.id.toString()"
							[checked]="checkFlag(f.sportServicesFlag.value, tpt.id)"
							(change)="onFlagChange($event, 'sportServicesFlag', tpt.id)"
							>{{ tpt.name }}</mat-checkbox
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<b>Amenities</b>
					<mat-divider></mat-divider>
					<div class="d-flex flex-wrap column-gap-3 mb-2">
						<mat-checkbox
							*ngFor="let tpt of otherServicesFlags"
							[value]="tpt.id.toString()"
							[checked]="checkFlag(f.otherServicesFlag.value, tpt.id)"
							(change)="onFlagChange($event, 'otherServicesFlag', tpt.id)"
							>{{ tpt.name }}</mat-checkbox
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<b>Co-location</b>
					<mat-divider></mat-divider>
					<div class="d-flex flex-wrap column-gap-3 mb-2">
						<mat-checkbox
							*ngFor="let tpt of coLocatedWithFlags"
							[value]="tpt.id.toString()"
							[checked]="checkFlag(f.coLocationFlag.value, tpt.id)"
							(change)="onFlagChange($event, 'coLocationFlag', tpt.id)"
							>{{ tpt.name }}</mat-checkbox
						>
					</div>
				</div>
			</div>

			<b>Additional Site Comments</b>
			<mat-divider></mat-divider>
			<!-- Comments-->
			<div class="row">
				<div class="col-12">
					<mat-form-field class="w-100" appearance="fill">
						<mat-label>Comments</mat-label>
						<textarea matInput formControlName="comments"></textarea>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
</form>
