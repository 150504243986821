import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { AxActivity } from 'src/app/shared/models/axActivity';
import { AxActivityProgramme } from 'src/app/shared/models/axActivityProgramme';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient
  ) { }

	getActivties(): Observable<AxActivity[]>{
    return this.http.get<AxActivity[]>(Api.getActivitiesUrl);
  }

  getActivtyProgrammes(): Observable<AxActivityProgramme[]>{
    return this.http.get<AxActivityProgramme[]>(Api.getActivityProgrammesUrl);
  }

}
