import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { organisationAdminGuard } from './core/guards/organisation-admin.guard';
import { LoginShellComponent } from './modules/home/login-shell/login-shell.component';

import { HomeComponent } from './modules/home/home/home.component';
import { LoginComponent } from './modules/home/login/login.component';
import { PasswordChangeComponent } from './modules/settings/password-change/password-change.component';
import { PasswordForgotComponent } from './modules/home/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './modules/home/password-reset/password-reset.component';
import { UploadFileComponent } from './modules/etl/upload-file/upload-file.component';
import { JobErrorsComponent } from './modules/etl/job-errors/job-errors.component';
import { MapSitesComponent } from './modules/admin/map-sites/map-sites.component';
import { MapMembershiptypeComponent } from './modules/admin/map-membershiptype/map-membershiptype.component';
import { MapActivityComponent } from './modules/admin/map-activity/map-activity.component';
import { DataIssuesComponent } from './modules/etl/data-issues/data-issues.component';


const routes: Routes = [
	// Login Section
	{
		path: 'login',
		component: LoginShellComponent,
		children: [
			{ path: '', component: LoginComponent },
			{ path: 'password-forgot', component: PasswordForgotComponent },
			{ path: 'password-reset/:id', component: PasswordResetComponent }
		]
	},
	{ path: '', component: HomeComponent, canActivate: [authGuard] },

	// SportsEye Top Level Modules (Lazy Loaded)
	{
		path: 'maps',
		loadChildren: () => import('./modules/maps/maps.module').then(m => m.MapsModule),
	},
	{
		path: 'dashboardsv2',
		loadChildren: () => import('./modules/dashboardsv2/dashboardsv2.module').then(m => m.DashboardsV2Module)
	},
	{
		path: 'academy',
		loadChildren: () => import('./modules/academy/academy.module').then(m => m.AcademyModule)
	},
	{
		path: 'reports',
		loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
	},
	{
		path: 'settings',
		loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
		canMatch: [authGuard]
	},
	{
		path: 'admin',
		loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
		canMatch: [authGuard]
	}

	// { path: 'errors/:id', component: JobErrorsComponent },
	// { path: 'map-sites/:id', component: MapSitesComponent },
	// { path: 'map-membership-type/:id', component: MapMembershiptypeComponent },
	// { path: 'map-activity/:id', component: MapActivityComponent },
	// { path: 'upload', component: UploadFileComponent, canActivate: [authGuard, organisationAdminGuard] },
	// { path: 'issues', component: DataIssuesComponent },
	// { path: 'job-errors/:id', component: JobErrorsComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
