// -- Angular Core Modules
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

// -- SportsEye Application Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './core/interceptors/tokenInterceptor';
import { HomeModule } from './modules/home/home.module';
import { EtlModule } from './modules/etl/etl.module';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { ReportsModule } from './modules/reports/reports.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		SharedModule,
		HomeModule,
		ReportsModule,
		EtlModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		AnalyticsService,
		Title
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
