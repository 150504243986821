import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  public errorMessages: string[] = [];

  constructor(private router: Router) { }

  public handleError (error: HttpErrorResponse): string[] {
    this.errorMessages = [];
    if(error.status === 500){
         return this.handle500Error(error);
        }
    else if(error.status === 403){
      return this.handle403Error(error)
    }
    else if(error.status === 404){
          return this.handle404Error(error)
        }
    else if(error.status === 400){
      return this.handle400Error(error)
    }
    else{
          return this.handleOtherError(error);
        }
  }

  private handle500Error = (err: HttpErrorResponse) => {
    if (err.error && err.error.title)
      this.errorMessages.push(err.error.title);
    else if (err.error && err.error.length < 256)
      this.errorMessages.push(err.error);
    else
      this.errorMessages.push('Something went wrong.');

    return this.errorMessages;

  }

  private handle404Error = (err: HttpErrorResponse) => {
    if (err.error && err.error.detail)
        this.errorMessages.push(err.error.detail);
    else
      this.errorMessages.push(err.error ? err.error : err.statusText);
    return this.errorMessages;
  }

  private handle403Error = (err: HttpErrorResponse) => {
    if (err.error && err.error.detail)
        this.errorMessages.push(err.error.detail);
    else if (err.error && err.error.title)
        this.errorMessages.push(err.error.title);
    else
      this.errorMessages.push(err.error ? err.error : err.statusText);
    return this.errorMessages;
  }

  private handleOtherError = (err: HttpErrorResponse) => {
    if (err.error)
    {
      if (typeof err.error == 'string' && err.error.length < 256)
          this.errorMessages.push(err.statusText);
      else if (err.error && err.error.detail)
          this.errorMessages.push(err.error.detail)
      else
         this.errorMessages.push('Something went wrong.');

    }else{
      this.errorMessages.push(err.statusText);
    }

    return this.errorMessages;
  }

  private handle400Error = (err: HttpErrorResponse) => {

    //Case when error.errors is an array
    if (err && err.error && err.error.errors)
    {
      //loop via errors array and return all errors
      for(var key in err.error.errors) {
          this.errorMessages.push(err.error.errors[key]);
      }
    }
    // if error has message property
    else if (err && err.error && err.error.message)
    {
      this.errorMessages.push(err.error.message);
    }
    // if error has title property
    else if (err && err.error && err.error.detail)
    {
      this.errorMessages.push(err.error.detail);
    }
    // if error has title property
    else if (err && err.error && err.error.title)
    {
      this.errorMessages.push(err.error.title);
    }
    // if error comes back as string
    else if (err && err.error)
    {
        if (typeof err.error === 'string')
        {
            this.errorMessages.push(err.error)
        }
    }

    return this.errorMessages;
  }


}
