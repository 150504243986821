<table
	mat-table
	[dataSource]="dataSource"
	matSort
	(matSortChange)="sortData($event)"
	matSortActive="createdDateTimeUTC"
	matSortDirection="desc"
	matSortDisableClear
	class="ax-table">
	<ng-container matColumnDef="jobId">
		<th mat-header-cell *matHeaderCellDef class="ps-3">JOB ID</th>
		<td mat-cell *matCellDef="let element" class="ps-3">{{ element.jobId }}</td>
	</ng-container>

	<ng-container matColumnDef="fileType">
		<th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell">FILE TYPE</th>
		<td mat-cell *matCellDef="let element" class="d-none d-md-table-cell">{{ element.job.jobTypeIndStr }}</td>
	</ng-container>

	<ng-container matColumnDef="createdDateTimeUTC">
		<th mat-header-cell *matHeaderCellDef>DATE / TIME UPLOADED (UTC)</th>
		<td mat-cell *matCellDef="let element">{{ element.job.createdDateTimeUTC | date : 'dd/MM/yyyy hh:mm a' }}</td>
	</ng-container>

	<ng-container matColumnDef="statusDateTimeUTC">
		<th mat-header-cell *matHeaderCellDef>STATUS DATE / TIME (UTC)</th>
		<td mat-cell *matCellDef="let element">{{ element.statusDateTimeUTC | date : 'dd/MM/yyyy hh:mm a' }}</td>
	</ng-container>

	<ng-container *ngIf="isSports" matColumnDef="datayear">
		<th mat-header-cell *matHeaderCellDef>DATA YEAR</th>
		<td mat-cell *matCellDef="let element">{{ element.datayear }}</td>
	</ng-container>

	<ng-container matColumnDef="statusIndStr">
		<th mat-header-cell *matHeaderCellDef class="text-end">JOB STATUS</th>
		<td mat-cell *matCellDef="let element" class="text-end">
			<span
				class="status"
				[ngClass]="{
					status__submited: element.statusInd === 1,
					status__processing: element.statusInd > 1 && element.statusInd <= 99,
					status__error: element.statusInd >= 1000 && element.statusInd < 9999,
					status__deleted: element.statusInd === 9999,
					status__completed: element.statusInd === 100
				}">
				{{ element.statusIndStr }}
			</span>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row class="selectable-row" *matRowDef="let row; columns: displayedColumns" (click)="showJobHistory(row)"></tr>
</table>
