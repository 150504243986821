export const environment = {
	production: false,
	baseApi: 'https://ase-sportseye-uat-anz-001.azurewebsites.net/api/v1',
	academyApi: 'https://strapi.sportseye.app',
	baseSEApiUrl: 'https://ase-sportseye-uat-anz-001.azurewebsites.net',
	mapbox: {
		accessToken: 'pk.eyJ1IjoiYWN0aXZleGNoYW5nZS1jdXN0IiwiYSI6ImNrM292dnI2eDI1Z2MzaHBobWk4Z3Q2cnQifQ.Nx-qysX5Bjm33Zwux6H1dA'
	},
	vimeoVideoURL: 'https://player.vimeo.com/video/',
	registerLink: 'https://activexchange.org/contact-us',
	termsOfUseLink: 'https://activexchange.org/data-intelligence',
	instanceCountryISO3166: 'AUS',
	currencyCodeISO4217: 'AUD',
};
