import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { AuthService } from '../../../core/services/auth.service';
import { MessageService } from '../../../core/services/message.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl: string = '';
  registerUrl: string = '';
  termsOfUseUrl: string = 'https://activexchange.org/data-intelligence';
  hide = true;
  serverErrors: string[] = [];
  form = this.formBuilder.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    rememberMe: [false]
  });
  loading: boolean = false;

  get f(){
    return this.form.controls;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errHandlerService: ErrorHandlerService
    ) { }

  ngOnInit(): void {
    this.authService.setIsLoggedIn('false');
    this.registerUrl = environment.registerLink;
    this.route.queryParams.subscribe(
      params => {
        this.returnUrl = params.redirectURL??'';
      }
    );
  }


  async onSubmit(){
    this.serverErrors = [];
    if (this.form.invalid){
      console.log(this.f.username.value);
      this.serverErrors.push("Invalid input values");
      return;
    }


    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value).subscribe(
      data => {
        this.loading = false;
        if (data)
        {
          this.authService.setSessionStorageFromAuthResponse(data,this.f.rememberMe.value);
          this.messageService.isLoggedIn(true);
          if (this.returnUrl.length > 1)
            this.router.navigateByUrl(this.returnUrl);
          else
            this.router.navigate(['/']);


          //this.messageService.showSuccess('Successfully Logged into system');
        }else{
          this.serverErrors.push('Invalid Credentials');
        }
      }, err=> {
        this.handleError(err);
      }
    );



  }

  handleError(err: any)
  {
    this.loading = false;
    this.serverErrors = this.errHandlerService.handleError(err);
  }

}
