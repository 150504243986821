import { MovementMonitoringItemDto } from 'src/app/shared/models/movement';

export module MapMessaging {
	/**
	 * Map Message
	 * Send a message to the MapComponent to alter behavior
	 */
	export interface MapMessage {
		messageType: Enums.MapMessageTypeInd;
		messageData?:
			| MessageDataDTOs.NIDMessageDataDTO
			| MessageDataDTOs.MapboxLayerMessageDataDTO
			| MessageDataDTOs.MovementMessageDataDTO
			| MessageDataDTOs.EditSiteLocationMessageDataDTO
			| MessageDataDTOs.EditSiteQuadkeyMessageDataDTO
			| MessageDataDTOs.EditMovementMonitoringItemQuadkeysDataDto
			| MessageDataDTOs.ApiLayerMessageDataDTO
			| MessageDataDTOs.LoadingIndicatorMessageDataDTO
			| MessageDataDTOs.ShowNIDModalMessageDataDTO;
	}

	/** ENUM DEFINITIONS */
	export namespace Enums {
		/** Message Types */
		export enum MapMessageTypeInd {
			NID,
			MapBoxLayer,
			MovementData,
			EditSiteLocation,
			EditSiteQuadkey,
			AddNewSite,
			EditMovementMonitoringItemQuadkeys,
			ApiLayer,
			LoadingIndicator,
			ShowNIDModal,
			SetMapComponentMode,
		}

		/** Visibility Action Types */
		export enum VisibilityActionTypeInd {
			Hide,
			Show,
		}

		/** NID Action Types */
		export enum NIDActionTypeInd {
			SitesAsPins,
			SitesAsClusters,
			AddNewSite,
			SiteLocationEdit,
			SiteQuadkeyEdit,
			NewSites,
		}

		/** The operating modes the Map Component can be in */
		export enum MapComponentMode {
			Normal = 0,
			AddNewSite = 1,
			EditSiteLocation = 2,
			AddQuadkeyCollection = 3,
			EditQuadkeyCollection = 4,
			EditSiteQuadkeys = 5,
		}
	}

	/** Message Data DTOs */
	export namespace MessageDataDTOs {
		/**
		 * NID Message Data
		 */
		export class NIDMessageDataDTO {
			constructor(
				public actionType: Enums.NIDActionTypeInd,
				public visibilityType: Enums.VisibilityActionTypeInd,
				public siteId?: number,
				public concurrencyStamp?: string
			) {}
		}

		/**
		 * Movement Data Layer Information
		 */
		export class MovementMessageDataDTO {
			layerName: string;

			constructor(
				public visibilityType: Enums.VisibilityActionTypeInd,
				public tilesetId: string,
				public hourInd: number,
				public weekdayInd: number,
				public threshold: number
			) {
				this.layerName = 'movement';
			}

			public uniqueIdentifier(): string {
				return this.tilesetId + 'H' + this.hourInd + 'D' + this.weekdayInd;
			}
		}

		/**
		 * Mapbox Style/Layer Information
		 */
		export type MapboxLayerType = 'fill' | 'line' | 'symbol';
		export class MapboxLayerMessageDataDTO {
			modalDictionaryName?: string = '';
			tooltipDictionaryName?: string = '';

			// - required by member and demand layers
			apiEndpoint?: string = '';
			geographicLevel?: number = -1;
			organisationId?: number = -1;

			constructor(
				public buttonContainerId: string,
				public visibilityType: Enums.VisibilityActionTypeInd,
				public styleId: string,
				public layerName: string,
				public color: string,
				public layerType: MapboxLayerType | string
			) {}
		}

		/**
		 * Edit Site Location Information
		 * */
		export class EditSiteLocationMessageDataDTO implements IEditSiteData {
			id: number;
			name: string;
			address: string;
			latitude: number;
			longitude: number;
			concurencyStamp?: string;
		}

		/**
		 * Edit NID Site Quadkey Collection
		 * */
		export class EditSiteQuadkeyMessageDataDTO implements IEditSiteData {
			id: number;
			name: string;
			address: string;
			latitude: number;
			longitude: number;
			concurencyStamp?: string;
			quadkeys: string[];
			viewOnly: boolean;
		}

		/**
		 * Edit Quadkey Collection
		 */
		export interface EditMovementMonitoringItemQuadkeysDataDto {
			item: MovementMonitoringItemDto;
			isDirty: boolean;
		}

		/**
		 * Represents a message to the map to request data from
		 * an API backend
		 */
		export type ApiLayerType = 'circle' | 'heatmap';
		export class ApiLayerMessageDataDTO {
			modalDictionaryName?: string = '';
			tooltipDictionaryName?: string = '';

			// - required by member and demand layers
			geographicLevel?: number = -1;

			constructor(
				public buttonContainerId: string,
				public buttonId: string,
				public visibilityType: Enums.VisibilityActionTypeInd,
				public mergeLayerName: string,
				public dataFieldName: string,
				public color: number,
				public layerType: ApiLayerType | string,
				public apiEndpoint: string,
				public organisationId: number
			) {}
		}

		/**
		 * Represents a message to the map to set the state
		 * of the loading indicator
		 */
		export class LoadingIndicatorMessageDataDTO {
			constructor(public visibilityType: Enums.VisibilityActionTypeInd) {}
		}

		/**
		 * Represents a message to the map to open the NID site modal
		 */
		export class ShowNIDModalMessageDataDTO implements IEditSiteData {
			id: number;
			name: string;
			address: string;
			latitude: number;
			longitude: number;
			concurencyStamp?: string;
		}

		export abstract class IEditSiteData {
			id: number;
			name: string;
			address: string;
			latitude: number;
			longitude: number;
			concurencyStamp?: string;
		}

		/**
		 * Represents a message to inform the map component that
		 * a functionality class has altered the state of the map
		 */
		// export class SetMapComponentModeMessageDataDTO {
		// 	constructor(
		// 		public mode: Enums.MapComponentMode
		// 	) { }
		// }
	}
}
