import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { EtlService } from 'src/app/core/services/etl.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { JobHistory } from 'src/app/shared/models/jobHistory';
import { AuthService } from '../../../core/services/auth.service';

@Component({
	selector: 'app-job-history',
	templateUrl: './job-history.component.html',
	styleUrls: ['./job-history.component.scss'],
})
export class JobHistoryComponent implements OnInit {
	@Input() organisationId: number;

	@Output() rowClicked = new EventEmitter<JobHistory>();

	loading: boolean;
	serverErrors: string[] = [];
	jobHisotry: JobHistory[] = [];
	isAdmin: boolean = false;
	isSports: boolean = false;

	displayedColumns: string[] = ['jobId', 'fileType', 'createdDateTimeUTC', 'statusDateTimeUTC', 'statusIndStr'];
	dataSource = new MatTableDataSource<JobHistory>();
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private orgService: OrganisationService,
		private etlService: EtlService,
		private errHandlerService: ErrorHandlerService,
		private authService: AuthService,
		private router: Router
	) {
		this.isAdmin = authService.isUserSportsEyeAdmin();
	}

	ngOnInit(): void {
		let isAdmin = this.authService.isUserSportsEyeAdmin();
		let platform = '';
		if (!isAdmin) {
			platform = this.authService.getPlatform();
		} else {
			platform = sessionStorage.getItem('AdminOrgPlatform');
		}

		if (platform === 'Sports' || platform === 'Sports_Pro' || platform === 'Sports_PeakBody') {
			this.isSports = true;
			this.displayedColumns = [
				'jobId',
				'fileType',
				'createdDateTimeUTC',
				'statusDateTimeUTC',
				'datayear',
				'statusIndStr',
			];
		}

		this.loadJobHistory(this.organisationId);
	}

	public loadJobHistory(orgId: number) {
		if (!orgId) {
			return;
		}
		this.loading = true;
		this.etlService.getJobHistoryForOrganisation(orgId.toString()).subscribe(
			(data: JobHistory[]) => {
				this.jobHisotry = data;
				this.dataSource.data = data;
				this.loading = false;
			},
			(err) => this.handleError(err)
		);
	}

	handleError(err: any) {
		this.loading = false;
		this.serverErrors = this.errHandlerService.handleError(err);
	}

	showJobHistory(job: JobHistory) {
		this.router.navigate(['/admin/organisations/' + this.organisationId + '/jobs/' + job.jobId]);

		// if (job.statusInd === 1000) {
		// 	this.router.navigate(['job-errors', job.jobId]);
		// 	return;
		// }
		// console.log(job);
		// this.rowClicked.emit(job);
		// if (job.statusInd == 3) this.router.navigate(['job-errors', job.jobId]);
	}

	sortData(sort: Sort) {
		const data = this.jobHisotry.slice();
		if (!sort.active || sort.direction === '') {
			this.dataSource.data = data;
			return;
		}

		this.dataSource.data = data.sort((a, b) => {
			const isAsc = sort.direction === 'desc';
			switch (sort.active) {
				case 'createdDateTimeUTC':
					return this.compare(a.job.createdDateTimeUTC, b.job.createdDateTimeUTC, isAsc);
				default:
					return 0;
			}
		});
	}
	compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	isError(statusInd: number) {
		return statusInd >= 1000;
	}
}
