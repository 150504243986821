import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	Self,
} from '@angular/core';
import { NgControl, ControlValueAccessor, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { SliderValue } from '../../models/slider-value.type';

@Component({
	selector: 'app-slider-form-field',
	templateUrl: './slider-form-field.component.html',
	styleUrls: ['./slider-form-field.component.scss'],
	providers: [{ provide: MatFormFieldControl, useExisting: SliderFormFieldComponent }],
})
export class SliderFormFieldComponent
	implements OnInit, OnDestroy, MatFormFieldControl<SliderValue>, ControlValueAccessor
{
	@Input() color: ThemePalette;
	@Input() disableRipple: boolean;
	@Input() discrete: boolean;
	@Input() displayWith: (value: number) => string = (value: number) => `${value}`;
	@Input() max: number;
	@Input() min: number;
	@Input() showTickMarks: boolean;
	@Input() step: number;
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('aria-describedby') userAriaDescribedBy: string;

	@Input()
	get value(): SliderValue {
		if (this.endControl.value === null) {
			return this.startControl.value;
		}
		return { start: this.startControl.value, end: this.endControl.value };
	}
	set value(value: SliderValue) {
		if (typeof value === 'number') {
			this.startControl.setValue(value);
		} else {
			this.startControl.setValue(value.start);
			this.endControl.setValue(value.end);
		}
		this.onChange(value);
		this.valueChange.emit(value);
		this.stateChanges.next();
	}

	@Input()
	get required(): boolean {
		return this._required;
	}
	set required(req: BooleanInput) {
		this._required = coerceBooleanProperty(req);
		this.stateChanges.next();
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}
	set disabled(value: BooleanInput) {
		this._disabled = coerceBooleanProperty(value);
		if (this._disabled) {
			this.startControl.disable();
			this.endControl.disable();
		} else {
			this.startControl.enable();
			this.endControl.enable();
		}

		this.stateChanges.next();
	}

	@Output() valueChange = new EventEmitter<SliderValue>();

	@HostBinding() id = `app-slider-form-field-${SliderFormFieldComponent.nextId++}`;

	static nextId = 0;
	private _required = false;
	private _disabled = false;

	focused = false;
	stateChanges = new Subject<void>();
	touched = false;
	dragging = false;

	startControl: FormControl<number> = new FormControl(0, { nonNullable: true });
	endControl: FormControl<number | null> = new FormControl(null);

	onChange: any = () => {};
	onTouch: any = () => {};

	get placeholder(): string {
		return null;
	}
	set placeholder(_: string) {
		return;
	}

	get empty(): boolean {
		return false;
	}

	get shouldLabelFloat(): boolean {
		return true;
	}

	get errorState(): boolean {
		return (this.startControl.invalid || this.endControl.invalid) && this.touched;
	}

	controlType = 'app-slider-form-field';

	constructor(@Optional() @Self() public ngControl: NgControl, public elementRef: ElementRef<HTMLElement>) {
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		this.startControl.valueChanges.subscribe((_) => {
			if (!this.dragging) {
				this.onChange(this.value);
				this.valueChange.emit(this.value);
			}
		});
		this.endControl.valueChanges.subscribe((_) => {
			if (!this.dragging) {
				this.onChange(this.value);
				this.valueChange.emit(this.value);
			}
		});
	}

	ngOnDestroy(): void {
		this.stateChanges.complete();
	}

	onFocusIn() {
		if (!this.focused) {
			this.focused = true;
			this.stateChanges.next();
		}
	}

	onFocusOut(event: FocusEvent) {
		if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
			this.touched = true;
			this.focused = false;
			this.onTouch();
			this.stateChanges.next();
		}
	}

	setDescribedByIds(ids: string[]) {
		const controlElement = this.elementRef.nativeElement.querySelector('.slider-form-field-container')!;
		controlElement.setAttribute('aria-describedby', ids.join(' '));
	}

	onContainerClick(event: MouseEvent) {
		if ((event.target as Element).tagName.toLowerCase() != 'input') {
			this.elementRef.nativeElement.querySelector('input').focus();
		}
	}

	writeValue(value: SliderValue): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	handleDragStart() {
		this.dragging = true;
	}

	handleDragEnd() {
		this.dragging = false;
		this.onChange(this.value);
		this.valueChange.emit(this.value);
	}
}
